import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          
          <path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m2660 1471 c194 -21 435 -135 570 -271 243 -242 346 -546 300 -875 -10
-70 -50 -205 -76 -254 -8 -14 -14 -29 -14 -34 0 -24 -126 -193 -195 -262 -138
-137 -251 -203 -435 -257 -108 -31 -124 -33 -265 -32 -120 0 -168 4 -238 22
-236 59 -436 195 -582 398 -52 71 -124 218 -132 270 -5 33 -4 35 17 32 18 -2
31 -23 64 -98 80 -181 199 -325 311 -376 190 -87 459 -66 635 51 122 81 173
170 174 305 2 179 -54 210 -279 155 -132 -33 -175 -32 -250 5 -33 16 -63 30
-67 30 -5 0 -36 -13 -70 -30 -128 -59 -248 -75 -362 -45 -32 9 -88 19 -125 23
-65 8 -65 9 -28 16 23 5 74 3 130 -5 118 -17 192 -16 258 6 67 22 169 72 169
84 0 14 -123 127 -159 146 -49 25 -136 30 -191 11 -24 -8 -46 -14 -47 -12 -2
1 20 15 47 30 62 34 135 37 210 10 37 -13 70 -37 121 -88 l69 -70 33 13 c159
65 199 74 327 75 109 1 131 -2 173 -21 84 -39 131 -80 163 -141 25 -50 29 -68
29 -139 0 -46 2 -83 5 -83 3 0 15 24 28 53 55 123 69 256 32 307 -27 38 -63
48 -200 56 -63 4 -127 13 -141 20 -15 7 -56 49 -93 93 -84 102 -101 118 -159
147 -58 30 -162 35 -222 10 -56 -24 -53 -13 7 19 46 25 64 29 133 29 111 0
156 -23 251 -122 103 -109 121 -116 279 -107 l126 7 -18 27 c-50 76 -85 140
-95 176 -19 67 -14 164 10 215 12 25 19 34 15 20 -13 -52 -5 -141 18 -198 12
-31 67 -122 121 -202 103 -153 138 -232 138 -308 0 -44 1 -45 18 -26 65 72 77
97 76 164 0 36 -4 103 -8 150 -5 54 -4 94 2 110 9 21 11 16 11 -35 1 -34 8
-88 17 -120 36 -140 16 -205 -106 -345 -30 -34 -59 -69 -64 -77 -4 -9 -16 -23
-26 -33 -28 -28 -80 -145 -89 -198 -4 -26 -3 -62 2 -81 l8 -34 58 6 c167 15
334 238 392 522 21 105 15 285 -14 402 -27 108 -94 255 -149 327 -135 177
-322 304 -523 357 -77 21 -113 24 -250 24 -142 -1 -170 -4 -250 -27 -49 -14
-112 -35 -138 -47 -178 -77 -383 -282 -457 -456 -12 -27 -28 -63 -35 -80 -36
-83 -50 -170 -50 -320 0 -82 3 -164 7 -182 6 -27 4 -33 -13 -38 -12 -4 -25 -3
-29 2 -5 4 -14 57 -21 116 -40 347 132 717 425 914 218 146 433 200 686 174z
m341 -251 c11 -37 -1 -90 -21 -90 -5 0 -10 12 -11 28 l-1 27 -12 -27 c-9 -22
-15 -25 -24 -16 -19 19 -14 79 11 115 20 30 23 31 35 16 7 -10 17 -33 23 -53z
m-204 -41 c31 -19 43 -37 43 -65 0 -19 -15 -18 -36 4 -19 19 -20 21 2 -33 10
-25 -23 -17 -56 13 -20 19 -32 40 -36 70 l-7 43 29 -7 c16 -4 43 -15 61 -25z
m-193 -46 c4 -4 -3 -16 -16 -26 l-23 -18 23 6 c24 7 27 -1 12 -25 -11 -17
-104 -20 -121 -3 -15 15 55 73 90 73 15 0 31 -3 35 -7z m485 -22 c22 -14 -39
-71 -77 -71 -39 0 -47 11 -20 27 l23 12 -22 1 c-25 0 -29 10 -11 28 14 14 86
16 107 3z m-924 -95 c16 -12 18 -18 7 -40 -13 -29 -32 -27 -69 7 -28 25 -29
34 -5 40 32 9 48 7 67 -7z m865 -30 c0 -31 -46 -86 -71 -86 -22 0 -24 10 -6
29 10 11 8 12 -10 6 -16 -4 -23 -2 -23 7 0 21 13 36 45 52 44 23 65 21 65 -8z
m-241 4 c45 -13 71 -50 35 -50 -13 0 -14 -3 -5 -14 9 -10 8 -15 -1 -19 -23 -8
-55 11 -76 46 -28 48 -18 56 47 37z m-503 -86 c4 -22 2 -45 -6 -60 -17 -30
-30 -31 -31 -1 l0 22 -11 -22 c-20 -41 -35 -24 -29 32 2 23 50 77 62 69 5 -2
11 -21 15 -40z m114 -18 c0 -33 -21 -66 -40 -66 -5 0 -6 8 -3 18 6 14 4 15 -9
4 -21 -17 -33 1 -18 27 10 20 48 51 62 51 5 0 8 -15 8 -34z m107 -12 c-4 -42
-18 -63 -39 -64 -5 0 -8 8 -9 18 0 10 -3 12 -6 5 -9 -24 -24 -13 -21 14 2 18
15 35 37 50 19 13 36 23 39 23 2 0 2 -21 -1 -46z m536 -42 c-12 -25 -26 -38
-42 -40 -19 -3 -22 0 -17 13 4 13 2 16 -9 11 -22 -8 -19 27 4 48 10 9 32 21
47 25 37 10 42 -6 17 -57z m-940 44 c42 -17 67 -42 67 -67 0 -19 -1 -19 -27
-2 l-28 18 18 -27 c17 -28 17 -28 -6 -28 -35 0 -65 27 -77 68 -5 20 -10 40
-10 45 0 11 27 8 63 -7z m1187 -26 c14 -27 12 -43 -4 -37 -8 3 -17 1 -19 -6
-3 -9 -8 -8 -20 2 -17 13 -23 61 -11 74 10 9 42 -10 54 -33z m-462 18 c18 -18
15 -28 -10 -29 l-23 -2 23 -4 c12 -2 22 -9 22 -14 0 -5 -10 -13 -22 -18 -36
-14 -107 21 -108 52 0 23 98 35 118 15z m-984 -28 c29 -11 35 -40 9 -40 -14 0
-15 -2 -4 -9 17 -11 6 -31 -19 -31 -23 0 -80 47 -80 65 0 12 17 19 57 24 6 0
23 -3 37 -9z m1544 -43 c5 -24 -28 -87 -45 -87 -10 0 -12 6 -8 23 6 18 5 20
-6 10 -18 -18 -29 -16 -29 3 0 20 59 76 74 71 6 -2 12 -11 14 -20z m-194 -21
c19 -8 37 -24 41 -36 9 -29 3 -33 -30 -20 -39 15 -42 5 -6 -16 35 -21 32 -34
-8 -34 -44 0 -111 66 -111 110 0 15 75 12 114 -4z m-1204 -26 c13 -9 13 -11 0
-20 -13 -8 -12 -10 3 -10 20 0 22 -11 5 -28 -21 -21 -112 -11 -105 12 4 11 65
56 76 56 3 0 13 -5 21 -10z m321 -40 c5 0 9 7 9 16 0 14 3 14 15 4 8 -7 15
-24 15 -37 0 -29 -29 -83 -46 -83 -15 0 -34 44 -34 78 0 33 23 63 28 38 2 -9
8 -16 13 -16z m-120 -22 l1 -33 10 30 c14 39 17 42 28 25 17 -27 10 -75 -16
-114 l-26 -38 -15 22 c-21 30 -29 106 -13 125 20 24 30 18 31 -17z m610 5 c12
-14 12 -17 0 -21 -11 -4 -11 -6 0 -13 24 -14 -18 -22 -50 -9 -34 14 -48 39
-29 52 22 13 65 9 79 -9z m634 -15 c-17 -58 -67 -97 -104 -83 -13 5 -12 10 9
27 l25 21 -33 -8 c-32 -6 -32 -6 -21 18 14 31 43 45 92 46 35 1 38 -1 32 -21z
m-1578 -47 c24 -28 30 -56 13 -66 -5 -3 -10 -1 -10 4 0 6 -5 11 -11 11 -6 0
-9 -7 -5 -15 6 -16 -11 -20 -31 -7 -19 12 -34 61 -27 88 5 20 11 23 28 18 11
-4 31 -19 43 -33z m-389 -18 c2 -7 -5 -14 -15 -14 -16 -1 -16 -2 0 -6 22 -6
22 -19 0 -27 -18 -7 -83 11 -83 24 0 29 88 50 98 23z m1760 -19 c30 -34 28
-46 -5 -38 -28 7 -28 7 -10 -14 22 -24 9 -36 -26 -23 -30 12 -67 61 -67 91 0
21 3 22 43 16 27 -4 50 -15 65 -32z m-1458 -24 c27 -27 26 -43 -2 -35 -19 6
-21 5 -10 -6 17 -18 15 -29 -6 -29 -26 0 -60 27 -77 61 l-16 29 46 0 c32 0 51
-6 65 -20z m1665 -69 c-8 -17 -27 -34 -41 -37 -31 -8 -41 1 -24 21 15 18 -1
20 -18 3 -9 -9 -12 -6 -12 13 0 36 19 58 65 74 l40 13 3 -28 c2 -16 -4 -42
-13 -59z m-995 61 c0 -4 -6 -14 -12 -21 -11 -11 -9 -12 10 -6 35 10 28 -22
-10 -45 -41 -25 -98 -27 -98 -4 0 23 64 84 89 84 12 0 21 -4 21 -8z m-740
-108 c0 -22 -4 -25 -32 -22 -40 4 -44 27 -9 45 31 15 41 9 41 -23z m220 -10
c10 -25 3 -84 -10 -84 -21 0 -60 52 -60 80 0 34 7 37 27 13 13 -16 14 -16 10
-1 -12 38 19 31 33 -8z m1520 -30 c0 -25 -48 -84 -69 -84 -15 0 -23 18 -11 25
5 3 10 11 10 17 0 6 -6 6 -15 -2 -20 -16 -29 -7 -20 20 7 22 55 49 88 50 12 0
17 -8 17 -26z m-1636 10 c21 -8 20 -43 -1 -66 -24 -27 -33 -22 -33 20 0 49 6
57 34 46z m-373 -114 c27 -15 23 -30 -8 -31 -26 -1 -27 -1 -5 -7 30 -9 29 -26
-2 -30 -25 -3 -80 20 -91 38 -4 6 9 17 27 25 40 17 54 18 79 5z m25 -126 c-4
-12 -2 -15 9 -10 21 8 19 -17 -2 -37 -10 -8 -27 -17 -38 -19 -16 -2 -19 2 -17
21 3 26 28 61 45 61 5 0 6 -7 3 -16z m89 -31 c-6 -28 -6 -28 7 -5 16 27 28 19
28 -18 0 -35 -64 -100 -74 -75 -10 27 -7 84 6 105 19 30 40 25 33 -7z m222 13
c-4 -10 -1 -13 9 -9 21 8 17 -7 -8 -34 -43 -46 -79 -17 -42 35 17 25 49 31 41
8z m-388 -972 c44 -9 91 -58 91 -94 0 -76 -50 -120 -137 -120 l-53 0 0 -70 0
-70 -35 0 -35 0 0 180 0 180 69 0 c38 0 83 -3 100 -6z m398 -169 c34 -93 62
-173 63 -177 0 -5 -15 -8 -34 -8 -30 0 -36 4 -46 35 l-12 35 -73 0 -74 0 -12
-35 c-11 -31 -17 -35 -47 -35 -39 0 -41 -14 25 170 72 202 67 192 110 188 l38
-3 62 -170z m189 148 c1 -13 2 -79 3 -148 l1 -125 54 0 c47 0 54 -3 59 -22 3
-12 4 -25 1 -30 -3 -4 -45 -8 -95 -8 l-89 0 0 181 0 180 33 -3 c25 -2 32 -8
33 -25z m234 -48 l0 -75 80 0 80 0 0 75 0 75 35 0 35 0 0 -180 0 -180 -35 0
-35 0 0 75 0 75 -80 0 -80 0 0 -75 0 -75 -35 0 -35 0 0 180 0 180 35 0 35 0 0
-75z m513 8 c43 -114 97 -270 97 -282 0 -6 -14 -11 -31 -11 -25 0 -32 6 -45
35 l-14 35 -75 0 -74 0 -7 -35 c-6 -33 -8 -35 -46 -35 -34 0 -39 3 -33 18 11
24 108 290 119 325 4 12 16 17 44 17 39 0 39 0 65 -67z m344 56 c49 -18 73
-48 73 -91 0 -49 -13 -74 -50 -97 l-29 -17 39 -65 c22 -35 40 -68 40 -72 0 -5
-17 -7 -37 -5 -34 3 -40 8 -74 68 -24 44 -43 66 -58 68 -20 3 -21 -1 -21 -67
l0 -71 -35 0 -35 0 0 180 0 180 79 0 c44 0 92 -5 108 -11z m322 1 c56 -16 81
-46 81 -98 0 -38 -5 -50 -35 -78 -19 -18 -35 -36 -35 -41 0 -4 16 -33 35 -63
19 -30 35 -58 35 -63 0 -4 -16 -7 -36 -5 -34 3 -40 9 -73 68 -26 45 -44 66
-59 68 -21 3 -22 0 -22 -67 l0 -71 -35 0 -35 0 0 180 0 180 73 0 c39 0 87 -4
106 -10z m237 -70 c21 -44 40 -80 43 -80 4 0 23 36 43 80 36 78 38 80 72 80
20 0 36 -2 36 -5 0 -8 -101 -199 -111 -210 -5 -5 -9 -40 -9 -77 l0 -68 -30 0
c-16 0 -30 1 -31 3 0 1 -3 31 -6 67 -4 51 -15 85 -59 172 -30 59 -54 110 -54
113 0 3 15 5 34 5 32 0 35 -3 72 -80z m-1370 -485 c14 -14 23 -29 20 -34 -7
-10 -54 -2 -62 10 -3 5 -16 9 -30 9 -42 0 -28 -41 21 -63 77 -36 94 -71 56
-118 -36 -44 -151 -23 -151 28 0 18 45 17 60 -2 18 -22 54 -15 58 11 2 17 -7
25 -47 43 -47 20 -81 52 -81 77 0 6 12 23 26 38 36 36 95 36 130 1z m194 -56
c0 -95 10 -119 48 -119 45 0 52 16 52 112 0 87 0 88 25 88 25 0 25 -1 25 -91
0 -101 -7 -122 -50 -144 -46 -24 -78 -19 -116 19 -34 34 -34 35 -34 125 0 90
0 91 25 91 25 0 25 -1 25 -81z m440 61 c24 -24 26 -57 3 -82 -15 -17 -15 -20
6 -39 17 -16 21 -28 16 -48 -11 -44 -29 -54 -109 -59 l-76 -5 0 127 0 126 70
0 c57 0 74 -4 90 -20z"/>
<path d="M2425 2866 c-144 -30 -184 -59 -100 -72 56 -8 117 1 215 31 41 13 89
27 105 30 27 7 28 8 10 15 -30 13 -162 10 -230 -4z"/>
<path d="M1282 1588 c3 -52 4 -53 36 -56 45 -5 82 21 82 56 0 40 -17 52 -73
52 l-48 0 3 -52z"/>
<path d="M1666 1599 c-2 -8 -14 -40 -25 -71 -11 -32 -17 -59 -13 -59 103 -16
107 -13 77 68 -29 75 -33 82 -39 62z"/>
<path d="M2621 1545 c-12 -36 -19 -70 -16 -75 8 -12 82 -13 89 -1 6 9 -29 120
-42 133 -5 5 -18 -21 -31 -57z"/>
<path d="M2942 1588 l3 -53 37 -3 c53 -5 78 12 78 51 0 45 -16 57 -73 57 l-48
0 3 -52z"/>
<path d="M3270 1585 l0 -55 44 0 c52 0 76 19 76 59 0 39 -17 51 -72 51 l-48 0
0 -55z"/>
<path d="M2770 1090 c0 -28 2 -30 40 -30 36 0 40 3 40 24 0 25 -16 36 -56 36
-20 0 -24 -5 -24 -30z"/>
<path d="M2772 988 c3 -31 5 -33 38 -30 19 1 39 7 45 13 17 17 -10 44 -49 47
-37 3 -37 3 -34 -30z"/>


        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
